import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.light,
  },
}));

const UrlsSection = ({ text }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography
        align="center"
        variant="h4"
        style={{ padding: 16, color: "white" }}
      >
        {text.toUpperCase()}
      </Typography>
    </div>
  );
};

export default UrlsSection;
