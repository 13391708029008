import React from "react";
import ImageList from "./ImageList";
import FilesUploader from "../../../components/FilesUploader";
import SectionHeader from "./SectionHeader";

const ImagesSection = ({ playset }) => {
  const [uploadImage, removeImage, loadingImages, images] = FilesUploader(
    playset,
    "images/playsets"
  );

  return (
    images.length > 0 && (
      <div>
        <SectionHeader text="Images" />
        <ImageList images={images} busy={loadingImages} />
      </div>
    )
  );
};

export default ImagesSection;
