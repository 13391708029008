import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@material-ui/core";
import UploadIcon from "@material-ui/icons/CloudUpload";
import FileIcon from "@material-ui/icons/Description";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { AppContext } from "../../components/AppContext";
import FileUploader from "../../components/FileUploader";
import ListItemFileUploadAction from "./ListItemFileUploadAction";
import EditSettingsForm from "./EditSettingsForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    padding: theme.spacing(2),
    minWidth: "300px",
    maxWidth: "500px",
    width: "100%",
  },
  text: {
    color: theme.palette.primary.main,
  },
}));

const Settings = ({ history, location }) => {
  const classes = useStyles();
  const context = useContext(AppContext);
  const [busy, setBusy] = useState(false);
  const [settings, setSettings] = useState(null);
  const [uploadTos, removeTos, loadingTos, tos] = FileUploader(`documents/tos`);
  useEffect(() => {
    setBusy(true);
    context
      .getSettings()
      .then((settings) => {
        setBusy(false);
        setSettings(settings);
      })
      .catch((error) => {
        context.setMessage({
          body: error.message || "Could not find settings.",
          options: {},
        });
        setBusy(false);
      });
  }, []);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.container}>
          <Typography className={classes.text} align="center" variant="h6">
            Settings
          </Typography>
          <List>
            <ListItem
              button
              component="a"
              href={tos ? tos.url : ""}
              target="_blank"
            >
              <ListItemIcon>
                <FileIcon />
              </ListItemIcon>
              <ListItemText primary="Terms of Service" />
              <ListItemFileUploadAction
                exists={tos}
                id="upload-tos"
                accept="application/pdf"
                onUpload={uploadTos}
                Icon={UploadIcon}
              />
            </ListItem>
            <Divider />
            {settings && (
              <EditSettingsForm settings={settings} context={context} />
            )}
          </List>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Settings);
