import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import OnBoardingForm from "./OnboardingForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    padding: theme.spacing(2),
    maxWidth: "500px",
    minWidth: "300px",
    width: "100%",
  },
  text: {
    color: theme.palette.primary.main,
  },
}));

const Welcome = ({ history, location }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography className={classes.text} align="center" variant="h6">
          Welcome! Just a few more things before we get started.
        </Typography>
        <OnBoardingForm />
      </div>
    </div>
  );
};

export default withRouter(Welcome);
