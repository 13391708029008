import React, { useState, useEffect } from "react";
import api, { onAuthChange, getClaims } from "../../../api";

const AppContext = React.createContext(null);

const AppContextProvider = props => {
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState(null);
  const [message, setMessage] = useState(null);
  const [claims, setClaims] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    onAuthChange(({ user, token }) => {
      getClaims(user).then(claims => {
        setClaims(claims);
        setCurrentUser(user);
        setToken(token);
        setIsLoading(false);
      });
    });
  }, []);

  return (
    <AppContext.Provider
      value={{
        token,
        claims,
        message,
        setMessage,
        currentUser,
        loggedIn: Boolean(currentUser),
        isLoading,
        ...api
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

const AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
