import { db, firebase } from "../firebase";

const apiRoot = "https://roc-solid-od.firebaseapp.com/api";

const login = async (user) => {
  const result = await firebase
    .auth()
    .signInWithEmailAndPassword(user.email, user.password);
  return result;
};

const logout = () => {
  return firebase.auth().signOut();
};

const loginWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  firebase.auth().signInWithRedirect(provider);
  return firebase.auth().getRedirectResult();
};

const updateProfile = (user) => {
  const profile = (({ displayName, photoURL }) => ({
    displayName,
  }))(user);
  return firebase.auth().currentUser.updateProfile(profile);
};

export const onAuthChange = (onChange) => {
  firebase.auth().onAuthStateChanged(
    (authUser) => {
      if (authUser) {
        firebase
          .auth()
          .currentUser.getIdToken()
          .then((token) => {
            onChange({ user: authUser, token });
          });
      } else {
        onChange({ user: null, token: null });
      }
    },
    () => {
      onChange({ user: null, token: null });
    }
  );
};

export const getClaims = async (user) => {
  if (!user) {
    return Promise.resolve({});
  }

  return user
    .getIdTokenResult()
    .then((idTokenResult) => {
      return idTokenResult.claims;
    })
    .catch((error) => {
      throw error;
    });
};

const register = async (user) => {
  const userCredential = await firebase
    .auth()
    .createUserWithEmailAndPassword(user.email, user.password);
  await firebase.auth().currentUser.sendEmailVerification({
    url: process.env.REACT_APP_emailVerificationUrl,
  });
  return userCredential;
};

const hasEmailPassword = async () => {
  const user = firebase.auth().currentUser;
  if (!user || !user.email) {
    return Promise.resolve(false);
  }
  return firebase
    .auth()
    .fetchSignInMethodsForEmail(user.email)
    .then(function (signInMethods) {
      return (
        signInMethods.indexOf(
          firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
        ) !== -1
      );
    })
    .catch(function (error) {
      return false;
    });
};

const sendEmailLink = async (user) => {
  const actionCodeSettings = {
    url: process.env.REACT_APP_verifyEmailLinkUrl,
    handleCodeInApp: true,
  };
  await firebase.auth().sendSignInLinkToEmail(user.email, actionCodeSettings);
  window.localStorage.setItem("emailForSignIn", user.email);
};

const confirmEmailLink = async (url) => {
  if (firebase.auth().isSignInWithEmailLink(url)) {
    let email = localStorage.getItem("emailForSignIn");
    if (!email) {
      email = prompt("Please provide your email for confirmation");
    }
    const result = await firebase.auth().signInWithEmailLink(email, url);
    localStorage.removeItem("emailForSignIn");
    return result;
  }
};

const sendPasswordResetLink = async (user) => {
  const actionCodeSettings = {
    url: process.env.REACT_APP_verifyEmailLinkUrl,
  };
  await firebase.auth().sendPasswordResetEmail(user.email, actionCodeSettings);
};

const reauthenticate = (currentPassword) => {
  var user = firebase.auth().currentUser;
  var cred = firebase.auth.EmailAuthProvider.credential(
    user.email,
    currentPassword
  );
  return user.reauthenticateWithCredential(cred);
};

const updatePassword = ({ currentPassword, newPassword }) => {
  return reauthenticate(currentPassword)
    .then(() => {
      const user = firebase.auth().currentUser;
      return user
        .updatePassword(newPassword)
        .then(() => {
          return true;
        })
        .catch((error) => {
          throw error;
        });
    })
    .catch((error) => {
      throw error;
    });
};

const updateEmail = ({ currentPassword, newEmail }) => {
  return reauthenticate(currentPassword)
    .then(() => {
      const user = firebase.auth().currentUser;
      return user
        .updateEmail(newEmail)
        .then(() => {
          return true;
        })
        .catch((error) => {
          throw error;
        });
    })
    .catch((error) => {
      throw error;
    });
};

const getUser = ({ token, id }) => {
  const url = `${apiRoot}/users/${id}`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        return response.text().then((text) => {
          const res = text ? JSON.parse(text) : { message: "User not found." };
          throw new Error(res.message);
        });
      } else {
        return response.text().then((text) => (text ? JSON.parse(text) : null));
      }
    })
    .then((data) => {
      return data.user;
    });
};

const getUserByEmail = ({ token, email }) => {
  const url = `${apiRoot}/users?email=${email}`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        if (response.status === 401) {
          throw new Error(response.statusText || "Not Authorized.");
        }
        return response.text().then((text) => {
          const res = text ? JSON.parse(text) : { message: "User not found." };
          throw new Error(res.message);
        });
      } else {
        return response.text().then((text) => (text ? JSON.parse(text) : null));
      }
    })
    .then((data) => {
      return data.user;
    });
};

const updateUser = ({ token, userRecord, uid }) => {
  if (!userRecord.password) {
    delete userRecord.password;
  }

  const url = `${apiRoot}/users/${uid}`;
  const options = {
    method: "PUT",
    body: JSON.stringify({
      ...userRecord,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        return response.text().then((text) => {
          const res = text
            ? JSON.parse(text)
            : { message: "User not updated." };
          throw new Error(res.message);
        });
      } else {
        return response.text().then((text) => (text ? JSON.parse(text) : null));
      }
    })
    .then((data) => {
      return data.user;
    });
};

const saveAccount = async (uid, account) => {
  const ref = db.collection("accounts").doc(uid);
  return ref.set(account, { merge: true }).then(() => getAccount({ uid }));
};

const getAccount = async ({ uid }) => {
  const ref = db.collection("accounts").doc(uid);
  return ref
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data();
      } else {
        return null;
      }
    })
    .catch((error) => {
      return null;
    });
};

const getPlayset = async (id) => {
  const ref = db.collection("playsets").doc(id);
  return ref
    .get()
    .then((doc) => {
      if (doc.exists) {
        return { ...doc.data(), id: doc.id };
      } else {
        return null;
      }
    })
    .catch((error) => {
      return null;
    });
};

const getPlaysets = async () => {
  const ref = db.collection("playsets");
  return ref
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        return [];
      }
      const results = [];
      snapshot.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      return results;
    })
    .catch((err) => {
      console.log("Error getting documents", err);
      return [];
    });
};

const savePlayset = async (playset) => {
  const ref = db.collection("playsets").doc(playset.id);
  return ref.set(
    {
      ...playset,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      user: firebase.auth().currentUser.uid,
    },
    { merge: true }
  );
};

const createPlayset = async (playset) => {
  const ref = db.collection("playsets").doc();
  return ref.set(
    {
      ...playset,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      user: firebase.auth().currentUser.uid,
    },
    { merge: true }
  );
};

const saveSettings = async (settings) => {
  const ref = db.collection("system").doc("settings");
  return ref.set(
    {
      ...settings,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      user: firebase.auth().currentUser.uid,
    },
    { merge: true }
  );
};

const getSettings = async () => {
  const ref = db.collection("system").doc("settings");
  return ref
    .get()
    .then((doc) => {
      if (doc.exists) {
        return { ...doc.data(), id: doc.id };
      } else {
        return {};
      }
    })
    .catch((error) => {
      return null;
    });
};

const getPlaylist = async ({
  playlistId,
  maxResults = 50,
  apiKey = "AIzaSyCNnqBDK-JBqDkAU-98XlqN7doZO1JV7cE",
}) => {
  const url = `https://www.googleapis.com/youtube/v3/playlistItems?part=id%2C%20contentDetails%2C%20snippet&maxResults=${maxResults}&playlistId=${playlistId}&key=${apiKey}`;
  const options = {};
  const noData = { items: [] };
  return fetch(url, options)
    .then((response) => {
      if (response.status === 400) {
        return noData;
      }

      if (response.status === 404) {
        return noData;
      }

      if (!response.ok) {
        const data = response
          .text()
          .then((text) => (text ? JSON.parse(text) : {}));
        throw Error(data.message || response.statusText);
      }
      return response.text().then((text) => (text ? JSON.parse(text) : []));
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export default {
  login,
  logout,
  loginWithGoogle,
  register,
  sendEmailLink,
  confirmEmailLink,
  sendPasswordResetLink,
  hasEmailPassword,
  updatePassword,
  updateEmail,
  updateProfile,
  updateUser,
  getClaims,
  getUser,
  getUserByEmail,
  getAccount,
  getPlaysets,
  getPlayset,
  saveAccount,
  savePlayset,
  createPlayset,
  getPlaylist,
  getSettings,
  saveSettings,
};
