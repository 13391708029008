import React from "react";
import { Typography } from "@material-ui/core";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;
    const style = {
      text: {
        marginTop: 16,
        marginBottom: 16
      }
    };
    if (hasError) {
      return (
        <div style={{ padding: 32 }}>
          <Typography style={style} align="center" variant="h5">
            Something went wrong
          </Typography>
          <Typography style={style} align="center" variant="subtitle1">
            {error.message}
          </Typography>
        </div>
      );
    } else {
      return children;
    }
  }
}

export default ErrorBoundary;
