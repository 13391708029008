import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import profileImage from "../../../images/profile.jpg";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
  },
  avatarInitials: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.light,
  },
}));

const UserAvatar = ({ user, open, handleMenu }) => {
  const classes = useStyles();
  const getInitials = (user) => {
    const words = user.displayName.replace(/[^A-Za-z0-9 ]/g, "").split(" ");
    const initials = words
      .map((x) => x.toUpperCase().substring(0, 1))
      .filter((x) => x !== "");
    return initials.slice(0, 2);
  };
  return user.photoURL ? (
    <Avatar
      alt={user.displayName}
      src={user.photoURL || profileImage}
      className={classes.avatar}
      aria-owns={open ? "menu-appbar" : undefined}
      aria-haspopup="true"
      onClick={handleMenu}
    />
  ) : user.displayName ? (
    <Avatar
      className={classes.avatarInitials}
      aria-owns={open ? "menu-appbar" : undefined}
      aria-haspopup="true"
      onClick={handleMenu}
    >
      {getInitials(user)}
    </Avatar>
  ) : (
    <Avatar
      alt={user.email}
      src={profileImage}
      className={classes.avatar}
      aria-owns={open ? "menu-appbar" : undefined}
      aria-haspopup="true"
      onClick={handleMenu}
    />
  );
};

export default UserAvatar;
