import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { Typography, TextField, makeStyles, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  fields: {
    display: "flex",
    flexDirection: "column",
  },
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  submit: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.contrastText,
    marginLeft: 0,
  },
  error: {
    color: theme.palette.error.dark,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const EmailLinkForm = ({ history, context }) => {
  const classes = useStyles();

  const handleRegister = (values, actions) =>
    context.sendEmailLink(values).then(
      (userCredential) => {
        context.setMessage({ body: "Email was sent.", options: {} });
        actions.setSubmitting(false);
      },
      (error) => {
        context.setMessage({ body: "Email was not sent.", options: {} });
        actions.setSubmitting(false);
        actions.setErrors(error);
        actions.setStatus({ msg: error.message });
      }
    );

  return (
    <Formik initialValues={{ email: "" }} onSubmit={handleRegister}>
      {({
        values,
        status,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className={classes.fields}>
            <TextField
              className={classes.field}
              type="email"
              name="email"
              placeholder="email address"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              autoComplete="on"
              variant="outlined"
            />
            <ErrorMessage name="email" component="div" />
          </div>
          <div className={classes.submit}>
            <Button
              size="large"
              color="secondary"
              fullWidth
              className={classes.button}
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </Button>
            {status && status.msg && (
              <Typography
                className={classes.error}
                color="error"
                align="center"
                variant="body1"
              >
                {status.msg}
              </Typography>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EmailLinkForm;
