import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AppContext } from "../../components/AppContext";
import * as routes from "../../routes";
import Home from "../../pages/Home";
import OnBoarding from "../../pages/OnBoarding";
import Profile from "../../pages/Profile";
import Account from "../../pages/Account";
import Security from "../../pages/Security";
import EmailLink from "../../pages/EmailLink";
import VerifyEmailLink from "../../pages/VerifyEmailLink";
import PrivateRoute from "../../components/PrivateRoute";
import PasswordReset from "../../pages/PasswordReset";
import Landing from "../../pages/Landing";
import SearchUser from "../../pages/Users/SearchUser";
import EditUser from "../../pages/Users/EditUser";
import Playsets from "../../pages/Playsets";
import AddPlayset from "../../pages/Playsets/AddPlayset";
import EditPlayset from "../../pages/Playsets/EditPlayset";
import Settings from "../../pages/Settings";

import AppShell from "./AppShell";

const AppRouter = () => {
  const { claims } = useContext(AppContext);

  return (
    <Router>
      <AppShell>
        <PrivateRoute path={routes.HOME} component={Home} auth={true} />
        <Switch>
          <PrivateRoute
            path={routes.ADDPLAYSET}
            component={AddPlayset}
            auth={claims.canCreatePlayset}
          />
          <PrivateRoute
            path={`${routes.PLAYSETS}/:id`}
            component={EditPlayset}
            auth={claims.canUpdatePlayset}
          />
          <PrivateRoute
            path={routes.PLAYSETS}
            component={Playsets}
            auth={claims.canReadPlayset}
          />
        </Switch>
        <PrivateRoute
          path={routes.ONBOARDING}
          component={OnBoarding}
          auth={true}
        />
        <PrivateRoute path={routes.PROFILE} component={Profile} auth={true} />
        <PrivateRoute path={routes.ACCOUNT} component={Account} auth={true} />
        <PrivateRoute path={routes.SECURITY} component={Security} auth={true} />
        <PrivateRoute
          exact
          path={routes.USERS_SEARCH}
          component={SearchUser}
          auth={claims.canReadUser}
        />
        <PrivateRoute
          path={`${routes.USERS}/:id`}
          component={EditUser}
          auth={claims.canUpdateUser}
        />
        <PrivateRoute
          path={`${routes.SETTINGS}`}
          component={Settings}
          auth={claims.canUpdateSettings}
        />
        <Route path={routes.ROOT} exact component={Landing} />
        <Route path={routes.EMAIL_LINK} component={EmailLink} />
        <Route path={routes.VERIFY_EMAIL_LINK} component={VerifyEmailLink} />
        <Route path={routes.PASSWORD_RESET} component={PasswordReset} />
      </AppShell>
    </Router>
  );
};

export default AppRouter;
