import React, { useState, useContext, useEffect } from "react";
import { Divider, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter, Redirect } from "react-router-dom";
import { AppContext } from "../../components/AppContext";
import PlaysetSelector from "./PlaysetSelector";
import Playset from "./Playset";
import * as routes from "../../routes";
import SectionHeader from "./Playset/SectionHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(1),
  },
  container: {
    width: "100%",
  },
  text: {
    color: theme.palette.primary.main,
  },
  title: { marginBottom: 16 },
}));

const Home = ({ history, location }) => {
  const classes = useStyles();
  const [account, setAccount] = useState(null);
  const [playsets, setPlaysets] = useState([]);
  const [playset, setPlayset] = useState(null);
  const [loadingPlaysets, setLoadingPlaysets] = useState(true);
  const [loadingAccount, setLoadingAccount] = useState(true);
  const [loadingPlayset, setLoadingPlayset] = useState(false);
  const context = useContext(AppContext);

  const handleSelect = ({ id }) => {
    setLoadingPlayset(true);
    context
      .getPlayset(id)
      .then((playset) => {
        setPlayset(playset);
        setLoadingPlayset(false);
      })
      .catch((error) => {
        setLoadingPlayset(false);
        context.setMessage({
          body: error.message || "Could not find playset.",
          options: {},
        });
      });
  };

  const handleSelectFavorite = ({ id }) => {
    context
      .saveAccount(context.currentUser.uid, { favoritePlaysetId: id })
      .then((doc) => {
        setAccount(doc);
      });
  };

  useEffect(() => {
    context.getAccount({ uid: context.currentUser.uid }).then((doc) => {
      setAccount(doc);
      setLoadingAccount(false);
    });
  }, []);

  useEffect(() => {
    if (account && playsets.length === 1) {
      handleSelect({ id: playsets[0].id });
    } else {
      if (account && account.favoritePlaysetId && playsets.length > 0) {
        handleSelect({ id: account.favoritePlaysetId });
      }
    }
  }, [account, playsets]);

  useEffect(() => {
    context.getPlaysets().then((items) => {
      setPlaysets(items.filter((x) => x.enabled));
      setLoadingPlaysets(false);
    });
  }, []);

  return (
    <React.Fragment>
      {(loadingPlaysets || loadingAccount || loadingPlayset) && (
        <LinearProgress color="secondary" variant="query" />
      )}

      {!loadingAccount && (
        <React.Fragment>
          {account && account.tosAccepted && account.welcomeVideoWatched ? (
            <div className={classes.root}>
              <div className={classes.container}>
                <SectionHeader
                  text={playset ? playset.description : "Select a playset"}
                />
                <PlaysetSelector
                  selectedId={playset && playset.id}
                  playsets={playsets}
                  onSelect={handleSelect}
                  onSelectFavorite={handleSelectFavorite}
                  favorite={account.favoritePlaysetId}
                />
                <Divider />
                {playset && !loadingPlayset && <Playset playset={playset} />}
              </div>
            </div>
          ) : (
            <Redirect
              to={{
                pathname: routes.ONBOARDING,
              }}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default withRouter(Home);
