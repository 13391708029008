export const drawerStyle = (theme) => (drawerWidth) => ({
  [theme.breakpoints.up("sm")]: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
});

export const isAdmin = (claims) => {
  if (!claims) {
    return false;
  }
  if (!claims.roles) {
    return false;
  }
  return claims.roles.includes("admin");
};
