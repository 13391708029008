import React from "react";
import { makeStyles, LinearProgress } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import FileInput from "./FileInput";
import FileUploader from "../../../components/FileUploader";
import PlaceholderImage from "../../../images/placeholder-image.png";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  root: {},
}));

const ImageSection = ({ playset }) => {
  const classes = useStyles();
  const [uploadImage, removeImage, loadingImage, image] = FileUploader(
    `images/playsets/${playset.id}/display/default`
  );

  return (
    <div className={classes.wrapper}>
      {loadingImage && <LinearProgress color="secondary" />}
      <div className={classes.root}>
        <img
          width="100%"
          src={image ? image.url : PlaceholderImage}
          alt="Display Images"
        />
        <FileInput
          id="upload-display-image"
          accept="image/*"
          onUpload={uploadImage}
          title="Upload Display Image"
          Icon={ImageIcon}
          groups={[]}
          skipMetadata={true}
        />
      </div>
    </div>
  );
};

export default ImageSection;
