import React from "react";
import DocumentsList from "./DocumentsList";
import FilesUploader from "../../../components/FilesUploader";
import SectionHeader from "./SectionHeader";

const DocumentsSection = ({ playset }) => {
  const [
    uploadDocument,
    removeDocument,
    loadingDocuments,
    documents,
  ] = FilesUploader(playset, "documents/playsets");
  return (
    documents.length > 0 && (
      <div>
        <SectionHeader text="Documents" />
        <DocumentsList documents={documents} busy={loadingDocuments} />
      </div>
    )
  );
};

export default DocumentsSection;
