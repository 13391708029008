import { useState, useEffect } from "react";
import { files } from "../../firebase";

export default function FilesUploader(playset, root) {
  const [busy, setBusy] = useState(false);
  const [items, setItems] = useState([]);

  const getFile = async (ref) => {
    const url = await ref.getDownloadURL();
    const metadata = await ref.getMetadata();
    return {
      url,
      path: ref.fullPath,
      name: ref.name,
      bucket: ref.bucket,
      metadata,
    };
  };

  const getFiles = async (playset) => {
    const refs = await files.ref(`${root}/${playset.id}`).listAll();
    const things = await Promise.all(refs.items.map((ref) => getFile(ref)));
    setItems(things);
  };

  const load = (file, customMetadata) => {
    setBusy(true);
    const task = files.ref(`${root}/${playset.id}/${file.name}`).put(file);
    task.on(
      "state_changed",
      (snapshot) => {
        console.log("progress");
      },
      (error) => {
        console.log(error);
        setBusy(false);
      },
      async () => {
        const fileRef = files.ref(`${root}/${playset.id}/${file.name}`);
        await fileRef.updateMetadata({
          customMetadata,
        });
        await getFiles(playset);
        setBusy(false);
      }
    );
  };

  const remove = (file) => {
    files
      .ref(file.path)
      .delete()
      .then(async () => {
        setBusy(true);
        await getFiles(playset);
        setBusy(false);
      });
  };

  useEffect(() => {
    if (!playset) {
      return;
    }
    setBusy(true);
    getFiles(playset);
    setBusy(false);
  }, [playset]);

  return [load, remove, busy, items];
}
