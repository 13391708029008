import React, { useState, useContext, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { Typography, makeStyles, LinearProgress } from "@material-ui/core";
import { AppContext } from "../../../components/AppContext";
import EditUserForm from "./EditUserForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    padding: theme.spacing(2),
    minWidth: 300,
    width: "100%",
  },
  text: {
    color: theme.palette.primary.main,
  },
}));

const EditUser = ({ location }) => {
  const { id } = useParams();
  const [busy, setBusy] = useState(false);
  const [user, setUser] = useState();
  const classes = useStyles();
  const context = useContext(AppContext);

  useEffect(() => {
    setBusy(true);
    context
      .getUser({ id, token: context.token })
      .then((user) => {
        setBusy(false);
        setUser(user);
      })
      .catch((error) => {
        context.setMessage({
          body: error.message || "Could find user.",
          options: {},
        });
        setBusy(false);
      });
  }, []);

  return (
    <React.Fragment>
      {busy && <LinearProgress color="secondary" variant="query" />}
      <div className={classes.root}>
        <div className={classes.container}>
          <Typography className={classes.text} align="center" variant="h6">
            Manage User
          </Typography>
          {!user && !busy && (
            <Typography
              className={classes.text}
              align="center"
              variant="subtitle1"
            >
              User not found
            </Typography>
          )}
          {user && <EditUserForm user={user} context={context} />}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditUser);
