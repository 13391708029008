import React, { useContext, useState } from "react";
import { AppContext } from "../../../components/AppContext";
import AppSnackbar from "../../../components/AppSnackbar";
import AppBar from "./AppBar";
import AppDrawer from "./AppDrawer";
import Loading from "./Loading";
import AppContent from "./AppContent";

const drawerWidth = 240;

const AppShell = ({ children }) => {
  const {
    loggedIn,
    isLoading,
    currentUser,
    claims,
    setMessage,
    message
  } = useContext(AppContext);
  const [mobileOpen, setMobileOpen] = useState(false);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <div>
        {loggedIn && (
          <React.Fragment>
            <AppBar
              setMobileOpen={setMobileOpen}
              mobileOpen={mobileOpen}
              user={currentUser}
              drawerWidth={drawerWidth}
            />
            <AppDrawer
              claims={claims}
              setMobileOpen={setMobileOpen}
              mobileOpen={mobileOpen}
              drawerWidth={drawerWidth}
            />
          </React.Fragment>
        )}
        <AppContent drawerWidth={drawerWidth}>{children}</AppContent>
        <AppSnackbar
          message={message}
          open={Boolean(message)}
          handleClose={() => setMessage(null)}
        />
      </div>
    );
  }
};

export default AppShell;
