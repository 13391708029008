import React from "react";
import { Formik, Form } from "formik";
import {
  Typography,
  TextField,
  makeStyles,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import ImagesSection from "./ImagesSection";
import ImageSection from "./ImageSection";
import DocumentsSection from "./DocumentsSection";
import UrlsSection from "./UrlsSection";

const useStyles = makeStyles((theme) => ({
  fields: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    marginTop: theme.spacing(1),
  },
  submit: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  button: {
    marginTop: theme.spacing(2),
  },
  error: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.error.dark,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const EditUserForm = ({ playset, context, onUpdate }) => {
  const classes = useStyles();

  const handleSave = (playset, actions) => {
    onUpdate({ ...playset });
    context.savePlayset(playset).then(
      (data) => {
        context.setMessage({ body: "Playset saved.", options: {} });
        actions && actions.setSubmitting(false);
      },
      (error) => {
        actions && actions.setSubmitting(false);
        actions && actions.setErrors(error);
        actions && actions.setStatus({ msg: error.message });
        context.setMessage({
          body: error.message || "Could not save playset.",
          options: {},
        });
      }
    );
  };

  const handleRemoveUrl = (playset) => (obj) => {
    delete playset.urls[obj.url];
    onUpdate({ ...playset });
    context.savePlayset(playset).then(
      () => {
        context.setMessage({ body: "Playset saved.", options: {} });
      },
      (error) => {
        context.setMessage({
          body: error.message || "Could not save playset.",
          options: {},
        });
      }
    );
  };
  return (
    <Formik initialValues={playset} onSubmit={handleSave}>
      {({
        values,
        status,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        errors,
        touched,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className={classes.fields}>
            <TextField
              className={classes.field}
              error={errors.description && touched.description}
              label="Description"
              name="description"
              placeholder="description"
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.description || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              className={classes.field}
              error={errors.description && touched.description}
              label="YouTube playlist"
              name="playlistId"
              placeholder="YouTube playlist"
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.playlistId || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.field}
                  name="enabled"
                  checked={values.enabled}
                  color="primary"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label="Enabled"
            />
          </div>

          <ImageSection playset={playset} />
          <ImagesSection playset={playset} />
          <DocumentsSection playset={playset} />
          <UrlsSection
            playset={playset}
            context={context}
            handleRemove={handleRemoveUrl(playset)}
            handleSave={handleSave}
          />

          <div className={classes.submit}>
            <Button
              color="secondary"
              fullWidth
              className={classes.button}
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Save
            </Button>
            {status && status.msg && (
              <Typography
                className={classes.error}
                color="error"
                align="center"
                variant="body1"
              >
                {status.msg}
              </Typography>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditUserForm;
