export const ROOT = "/";
export const HOME = "/home";
export const PLAYSETS = "/playsets";
export const SETTINGS = "/settings";
export const ADDPLAYSET = "/playsets/add";
export const ONBOARDING = "/onboarding";
export const PROFILE = "/profile";
export const ACCOUNT = "/account";
export const SECURITY = "/security";
export const USERS = "/users";
export const USERS_SEARCH = "/search";
export const EMAIL_LINK = "/email-link";
export const VERIFY_EMAIL_LINK = "/verify-email-link";
export const PASSWORD_RESET = "/password-reset";
