import React, { useState, useContext, useEffect } from "react";
import { Typography, LinearProgress } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { AppContext } from "../../components/AppContext";
import * as routes from "../../routes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    padding: theme.spacing(2),
    minWidth: "300px",
    width: "100%",
  },
  text: {
    color: theme.palette.primary.main,
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const Playsets = ({ history, location }) => {
  const classes = useStyles();
  const [busy, setBusy] = useState(true);
  const [playsets, setPlaysets] = useState([]);
  const context = useContext(AppContext);

  useEffect(() => {
    context.getPlaysets().then((items) => {
      setPlaysets(items);
      setBusy(false);
    });
  }, []);

  return busy ? (
    <LinearProgress color="secondary" variant="query" />
  ) : (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.container}>
          <Typography className={classes.text} align="center" variant="h6">
            Manage Playsets
          </Typography>

          <List component="nav" aria-label="playsets">
            {(playsets || []).map((playset) => (
              <div key={playset.id}>
                <ListItem
                  button
                  onClick={(e) =>
                    history.push({
                      pathname: routes.PLAYSETS + "/" + playset.id,
                    })
                  }
                >
                  <ListItemText primary={playset.description} />
                </ListItem>
                <Divider />
              </div>
            ))}
          </List>

          <Fab
            className={classes.fab}
            color="primary"
            aria-label="add"
            onClick={(e) => {
              history.push({ pathname: routes.ADDPLAYSET });
            }}
          >
            <AddIcon />
          </Fab>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Playsets);
