import React, { useEffect, useState } from "react";
import { files } from "../../../firebase";
import PlaceholderImage from "../../images/placeholder-image.png";

const PlaysetImage = ({ playset }) => {
  const [imageUrl, setImageUrl] = useState(PlaceholderImage);
  const [loadingPlayset, setLoadingPlayset] = useState(true);

  const getFileMeta = async (ref) => {
    const url = await ref.getDownloadURL();
    const metadata = await ref.getMetadata();
    return {
      url,
      path: ref.fullPath,
      name: ref.name,
      bucket: ref.bucket,
      metadata,
    };
  };

  const getFile = async (playset) => {
    const ref = files.ref(`images/playsets/${playset.id}/display/default`);
    return ref
      .getDownloadURL()
      .then(() => getFileMeta(ref))
      .catch(() => ({
        url: "",
        path: "",
        name: "",
        bucket: "",
        metadata: "",
      }));
  };

  useEffect(() => {
    setLoadingPlayset(true);
    getFile(playset)
      .then((playset) => {
        if (playset.url) {
          setImageUrl(playset.url);
        }
        setLoadingPlayset(false);
      })
      .catch((error) => {
        setLoadingPlayset(false);
      });
  }, [playset]);

  return (
    <div
      style={{
        width: "100%",
        height: 0,
        paddingBottom: "56.25%",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <img
          src={imageUrl}
          alt={playset.description}
          style={{
            width: "100%",
            padding: 0,
            margin: 0,
          }}
        />
      </div>
    </div>
  );
};

export default PlaysetImage;
