import React, { useState } from "react";
import { firebase } from "../../../firebase";
import {
  makeStyles,
  ListItemIcon,
  ListItemSecondaryAction,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  input: {
    display: "none",
  },
}));

const ListItemFileUploadAction = ({
  onUpload,
  accept,
  title,
  Icon,
  id,
  exists,
}) => {
  const [files, setFiles] = useState([]);
  const classes = useStyles();
  return (
    <ListItemSecondaryAction>
      <ListItemIcon>
        <input
          accept={accept}
          className={classes.input}
          id={id}
          type="file"
          value=""
          onChange={(e) => {
            if (e.target.files[0]) {
              setFiles([...e.target.files]);
              onUpload(e.target.files[0], {
                timestamp: Date.now().toString(),
                user: firebase.auth().currentUser.uid,
              });
            }
          }}
        />
        <label htmlFor={id}>
          {exists ? <Icon color="primary" /> : <Icon />}
        </label>
      </ListItemIcon>
    </ListItemSecondaryAction>
  );
};

export default ListItemFileUploadAction;
