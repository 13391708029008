import React from "react";
import DocumentsList from "./DocumentsList";
import { Typography } from "@material-ui/core";
import FileIcon from "@material-ui/icons/Description";
import FileInput from "./FileInput";
import FilesUploader from "../../../components/FilesUploader";

const groups = [
  { title: "General", sort: 1 },
  { title: "Guides", sort: 2 },
  { title: "Specifications", sort: 3 },
  { title: "Manuals", sort: 4 },
  { title: "Unboxing and Separation", sort: 5 },
];

const DocumentsSection = ({ playset }) => {
  const [
    uploadDocument,
    removeDocument,
    loadingDocuments,
    documents,
  ] = FilesUploader(playset, "documents/playsets");

  const handleRemove = (file) => (e) => {
    removeDocument(file);
  };
  return (
    <div
      style={{
        borderRadius: 8,
        border: "1px solid #cdcdcd",
        padding: 4,
        marginBottom: 16,
      }}
    >
      <Typography variant="overline" style={{ marginLeft: 8 }}>
        Documents
      </Typography>
      <DocumentsList
        documents={documents}
        handleRemove={handleRemove}
        busy={loadingDocuments}
      />
      <FileInput
        id="upload-pdf"
        accept="application/pdf"
        onUpload={uploadDocument}
        title="Add Document"
        Icon={FileIcon}
        groups={groups}
      />
    </div>
  );
};

export default DocumentsSection;
