import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppContext } from "../../../components/AppContext";
import { drawerStyle } from "../../../utilities";

const useStyles = makeStyles(theme => ({
  content: drawerStyle(theme)
}));

const AppContent = ({ children, drawerWidth }) => {
  const { loggedIn } = useContext(AppContext);
  const classes = useStyles(drawerWidth);

  return loggedIn ? (
    <div className={classes.content}>{children}</div>
  ) : (
    <div>{children}</div>
  );
};

export default AppContent;
