import React, { useContext } from "react";
import { Formik, Form } from "formik";
import SearchIcon from "@material-ui/icons/Search";
import {
  TextField,
  IconButton,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";
import { AppContext } from "../../../components/AppContext";

const useStyles = makeStyles((theme) => ({
  search: {},
}));

const UserSearchForm = ({ onGotUser, setBusy }) => {
  const context = useContext(AppContext);
  const classes = useStyles();

  return (
    <Formik
      initialValues={{ email: "" }}
      onSubmit={(values, actions) => {
        setBusy(true);
        context
          .getUserByEmail({ ...values, token: context.token })
          .then((user) => {
            onGotUser(user);
            actions.setSubmitting(false);
            setBusy(false);
          })
          .catch((error) => {
            actions.setSubmitting(false);
            actions.setErrors(error);
            context.setMessage({
              body: error.message || "Could find user.",
              options: {},
            });
            onGotUser(null);
            setBusy(false);
          });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit} className={classes.search}>
          <TextField
            error={errors.email && touched.email}
            label="Email"
            name="email"
            placeholder="email"
            margin="normal"
            fullWidth
            value={values.email || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    color="secondary"
                    className={classes.button}
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Form>
      )}
    </Formik>
  );
};

export default UserSearchForm;
