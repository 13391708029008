import React from "react";
import {
  GridList,
  GridListTile,
  GridListTileBar,
  makeStyles,
  LinearProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  gridList: {
    width: 500,
  },
  titleBar: {
    color: theme.palette.primary.light,
  },
  icon: {
    color: "white",
  },
}));

const compare = (a, b) => {
  return a.name.localeCompare(b.name);
};

const ImageList = ({ images, handleRemove, busy }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      {busy && <LinearProgress color="secondary" />}
      <div className={classes.root}>
        <GridList className={classes.gridList}>
          {images.sort(compare).map((file) => (
            <GridListTile
              key={file.path}
              component="a"
              href={file.url}
              target="_blank"
            >
              <img
                src={file.url}
                alt={
                  (file.metadata.customMetadata &&
                    file.metadata.customMetadata.title) ||
                  file.name
                }
              />
              {file.name && (
                <GridListTileBar
                  classes={{
                    root: classes.titleBar,
                    title: classes.title,
                  }}
                  title={
                    (file.metadata.customMetadata &&
                      file.metadata.customMetadata.title) ||
                    file.name
                  }
                />
              )}
            </GridListTile>
          ))}
        </GridList>
      </div>
    </div>
  );
};

export default ImageList;
