import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Toolbar, Button, IconButton } from "@material-ui/core";
import BackIcon from "@material-ui/icons/KeyboardArrowLeft";
import SignUpWrapper from "../SignUpWrapper";
import SignInWrapper from "../SignInWrapper";
import * as modes from "../../../modes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  welcome: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    backgroundColor: theme.palette.primary.dark,
  },
  welcomeText: {
    color: theme.palette.primary.contrastText,
    margin: theme.spacing(4),
  },
  action: {},
  actionContent: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  buttons: { marginLeft: "auto" },
  actions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  signup: {
    color: "white",
  },
  signin: {
    color: "white",
  },
  brand: {
    flexGrow: 1,
    color: "white",
  },
  brandLight: {
    flexGrow: 1,
  },
}));

const MobileLanding = ({
  mode,
  handleSwitchToSignIn,
  handleSwitchToSignUp,
  handleSwitchToWelcome,
  tagline,
  description,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {mode === modes.WELCOME && (
        <div className={classes.welcome}>
          <Toolbar>
            <Typography variant="h6" align="left" className={classes.brand}>
              Roc Solid On Demand
            </Typography>
            <div className={classes.buttons}>
              <Button
                edge="end"
                className={classes.signin}
                color="inherit"
                variant="outlined"
                onClick={handleSwitchToSignIn}
              >
                Log In
              </Button>
            </div>
          </Toolbar>
          <Typography variant="h3" align="left" className={classes.welcomeText}>
            {tagline}
          </Typography>
          <Typography
            variant="subtitle1"
            align="left"
            className={classes.welcomeText}
          >
            {description}
          </Typography>
          <div className={classes.actions}>
            <Button
              className={classes.signup}
              color="inherit"
              variant="outlined"
              onClick={handleSwitchToSignUp}
            >
              Sign Up For Free
            </Button>
          </div>
        </div>
      )}
      {(mode === modes.SIGNIN || mode === modes.SIGNUP) && (
        <div className={classes.action}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              variant="outlined"
              onClick={handleSwitchToWelcome}
            >
              <BackIcon />
            </IconButton>
            <Typography
              variant="h6"
              align="left"
              className={classes.brandLight}
            >
              Roc Solid On Demand
            </Typography>
            <div className={classes.buttons}>
              {mode === modes.SIGNIN ? (
                <Button
                  edge="end"
                  color="inherit"
                  variant="outlined"
                  onClick={handleSwitchToSignUp}
                >
                  Sign Up
                </Button>
              ) : (
                <Button
                  edge="end"
                  color="inherit"
                  variant="outlined"
                  onClick={handleSwitchToSignIn}
                >
                  Log In
                </Button>
              )}
            </div>
          </Toolbar>
          <div className={classes.actionContent}>
            {mode === modes.SIGNUP ? (
              <SignUpWrapper switchMode={handleSwitchToSignIn} />
            ) : (
              <SignInWrapper switchMode={handleSwitchToSignUp} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileLanding;
