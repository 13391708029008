import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { Typography, TextField, makeStyles, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  fields: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    marginTop: theme.spacing(1),
  },
  field: {
    backgroundColor: "white",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  submit: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  button: {
    marginTop: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.contrastText,
    marginLeft: 0,
  },
  error: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.error.dark,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const ChangePasswordForm = ({ context }) => {
  const classes = useStyles();

  const handleChangePassword = (values, actions) =>
    context.updatePassword(values).then(
      (result) => {
        context.setMessage({ body: "Password updated.", options: {} });
        actions.setSubmitting(false);
      },
      (error) => {
        context.setMessage({ body: `Password was not updated.`, options: {} });
        actions.setSubmitting(false);
        actions.setErrors(error);
        actions.setStatus({ msg: error.message });
      }
    );

  return (
    <Formik
      initialValues={{ currentPassword: "", newPassword: "" }}
      onSubmit={handleChangePassword}
    >
      {({
        values,
        status,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className={classes.fields}>
            <TextField
              className={classes.field}
              type="password"
              name="currentPassword"
              label="Current Password"
              placeholder="current password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.currentPassword}
              margin="normal"
              variant="outlined"
              autoComplete="on"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <ErrorMessage name="newPassword" component="div" />
            <TextField
              className={classes.field}
              type="password"
              name="newPassword"
              label="New Password"
              placeholder="new password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.newPassword}
              margin="normal"
              variant="outlined"
              autoComplete="on"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <ErrorMessage name="newPassword" component="div" />
          </div>
          <div className={classes.submit}>
            <Button
              color="secondary"
              fullWidth
              className={classes.button}
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Change Password
            </Button>
            {status && status.msg && (
              <Typography
                className={classes.error}
                color="error"
                align="center"
                variant="body1"
              >
                {status.msg}
              </Typography>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
