import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import PlaysetImage from "./PlaysetImage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    overflow: "hidden",
  },
  gridList: {
    padding: theme.spacing(2),
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    width: "100%",
  },
  gridTile: {
    cursor: "pointer",
    minWidth: 325,
  },
  gridTileSelected: {
    borderSize: 1,
    borderColor: theme.palette.primary.light,
    borderStyle: "solid",
    cursor: "pointer",
    minWidth: 325,
  },
  title: {
    color: "white",
  },
}));

export default function PlaysetSelector({
  playsets,
  onSelect,
  onSelectFavorite,
  selectedId,
  favorite,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GridList
        className={classes.gridList}
        cols={playsets.length > 1 ? 2.5 : 2}
      >
        {playsets.map((playset) => (
          <GridListTile
            className={
              playset.id === selectedId
                ? classes.gridTileSelected
                : classes.gridTile
            }
            key={playset.id}
            onClick={() => onSelect(playset)}
          >
            <PlaysetImage playset={playset} />
            <GridListTileBar
              title={playset.description}
              classes={{
                title: classes.title,
              }}
              actionIcon={
                <IconButton
                  aria-label={`star ${playset.description}`}
                  onClick={() => onSelectFavorite(playset)}
                >
                  {playset.id === favorite ? (
                    <StarIcon className={classes.title} />
                  ) : (
                    <StarBorderIcon className={classes.title} />
                  )}
                </IconButton>
              }
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}
