import React, { useContext, useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import { Button, Typography, makeStyles, useTheme } from "@material-ui/core";
import { AppContext } from "../../components/AppContext";
import Checkbox from "@material-ui/core/Checkbox";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Divider,
} from "@material-ui/core";
import FileIcon from "@material-ui/icons/Description";
import VideoIcon from "@material-ui/icons/FeaturedVideo";
import * as routes from "../../routes";
import FileUploader from "../../components/FileUploader";
import YouTube from "react-youtube";

const useStyles = makeStyles((theme) => ({
  fields: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    marginTop: theme.spacing(1),
  },
  submit: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  button: {
    marginTop: theme.spacing(2),
  },
  error: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.error.dark,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const OnBoardingForm = ({ history }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const smallOpts = {
    id: "rocsolidondemandvideowindow",
    width: 325,
    height: 183,
    playerVars: {
      autoplay: 1,
    },
  };
  const largeOpts = {
    id: "rocsolidondemandvideowindow",
    width: 640,
    height: 360,
    playerVars: {
      autoplay: 1,
    },
  };
  const context = useContext(AppContext);
  const classes = useStyles();
  const [busy, setBusy] = useState(false);
  const [settings, setSettings] = useState(null);
  const [uploadTos, removeTos, loadingTos, tos] = FileUploader(`documents/tos`);
  useEffect(() => {
    setBusy(true);
    context
      .getSettings()
      .then((settings) => {
        setBusy(false);
        setSettings(settings);
      })
      .catch((error) => {
        context.setMessage({
          body: error.message || "Could not find settings.",
          options: {},
        });
        setBusy(false);
      });
  }, []);

  const onReady = (event) => {
    // access to player in all event handlers via event.target
    //event.target.pauseVideo();
  };

  const validate = (values, props) => {
    const errors = {};
    if (!values.tosAccepted) {
      errors.tosAccepted = "Must accept the Terms of Service";
    }

    if (!values.welcomeVideoWatched) {
      errors.welcomeVideoWatched = "Must watch the welcome video";
    }

    return errors;
  };

  return (
    <Formik
      initialValues={{
        tosAccepted: false,
        waiverAccepted: false,
        welcomeVideoWatched: false,
      }}
      validate={validate}
      onSubmit={(values, actions) => {
        context.saveAccount(context.currentUser.uid, values).then(
          (result) => {
            context.setMessage({ body: "Onboarding complete.", options: {} });
            actions.setSubmitting(false);
            history.push(routes.HOME);
          },
          (error) => {
            actions.setSubmitting(false);
            actions.setErrors(error);
            actions.setStatus({ msg: "Could not complete onboarding." });
          }
        );
      }}
    >
      {({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className={classes.fields}>
            <List>
              <ListItem
                button
                component="a"
                href={tos ? tos.url : ""}
                target="_blank"
              >
                <ListItemIcon>
                  <FileIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Accept Terms of Service"
                  secondary={
                    errors.tosAccepted && touched.tosAccepted
                      ? errors.tosAccepted
                      : ""
                  }
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    className={classes.field}
                    name="tosAccepted"
                    checked={values.tosAccepted}
                    value="checked"
                    color="primary"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemIcon>
                  <VideoIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Watched the welcome video"
                  secondary={
                    errors.welcomeVideoWatched && touched.welcomeVideoWatched
                      ? errors.welcomeVideoWatched
                      : ""
                  }
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    className={classes.field}
                    name="welcomeVideoWatched"
                    checked={values.welcomeVideoWatched}
                    value="checked"
                    color="primary"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </div>

          {settings && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <YouTube
                videoId={settings.welcomeVideo}
                opts={isSmall ? smallOpts : largeOpts}
                onReady={onReady}
              />
            </div>
          )}

          <div className={classes.submit}>
            <Button
              color="secondary"
              fullWidth
              className={classes.button}
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Save
            </Button>
            {status && status.msg && (
              <Typography
                className={classes.error}
                color="error"
                align="center"
                variant="body1"
              >
                {status.msg}
              </Typography>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(OnBoardingForm);
