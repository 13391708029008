import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { AppContext } from "../../../components/AppContext";
import * as routes from "../../../routes";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import UserAvatar from "./UserAvatar";
import { drawerStyle } from "../../../utilities";
import * as modes from "../../../modes";

const useStyles = makeStyles((theme) => ({
  appBar: drawerStyle(theme),
  menuButton: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  title: {
    flexGrow: 1,
  },
}));

const MyAppBar = ({
  history,
  location,
  setMobileOpen,
  mobileOpen,
  user,
  drawerWidth,
}) => {
  const classes = useStyles({ drawerWidth });
  const context = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    setAnchorEl(null);
    history.push({ pathname: routes.PROFILE, state: { from: location } });
  };

  const handleMyAccount = () => {
    setAnchorEl(null);
    history.push(routes.ACCOUNT);
  };

  const handleMySecurity = () => {
    setAnchorEl(null);
    history.push(routes.SECURITY);
  };

  const handleSignOut = () => {
    context
      .logout()
      .then(() =>
        history.push({ pathname: routes.ROOT, state: { mode: modes.SIGNIN } })
      );
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <AppBar position="static" color="primary" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="Open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          Roc Solid On Demand
        </Typography>
        {user && <UserAvatar user={user} open={open} handleMenu={handleMenu} />}
        <div>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleProfile}>Profile</MenuItem>
            <MenuItem onClick={handleMyAccount}>Account</MenuItem>
            <MenuItem onClick={handleMySecurity}>Security</MenuItem>
            <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(MyAppBar);
