import React, { useContext } from "react";
import { Formik, Form } from "formik";
import { TextField, Button, Typography, makeStyles } from "@material-ui/core";
import { AppContext } from "../../components/AppContext";

const useStyles = makeStyles((theme) => ({
  fields: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    marginTop: theme.spacing(1),
  },
  submit: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  button: {
    marginTop: theme.spacing(2),
  },
  error: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.error.dark,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const ProfileForm = () => {
  const context = useContext(AppContext);
  const classes = useStyles();

  return (
    <Formik
      initialValues={context.currentUser}
      onSubmit={(values, actions) =>
        context.updateProfile(values).then(
          (result) => {
            context.setMessage({ body: "Profile saved.", options: {} });
            actions.setSubmitting(false);
          },
          (error) => {
            actions.setSubmitting(false);
            actions.setErrors(error);
            actions.setStatus({ msg: "Could not login." });
          }
        )
      }
    >
      {({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className={classes.fields}>
            <TextField
              className={classes.field}
              error={errors.displayName && touched.displayName}
              label="Display Name"
              name="displayName"
              placeholder="dislplay name"
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.displayName || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className={classes.submit}>
            <Button
              color="secondary"
              fullWidth
              className={classes.button}
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Save
            </Button>
            {status && status.msg && (
              <Typography
                className={classes.error}
                color="error"
                align="center"
                variant="body1"
              >
                {status.msg}
              </Typography>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileForm;
