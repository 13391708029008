import React from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as routes from "../../routes";
import {
  TextField,
  Button,
  Link as MuiLink,
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  fields: {
    display: "flex",
    flexDirection: "column",
  },
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  submit: {
    display: "flex",
    flexDirection: "column",
  },
  googleButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  link: {
    marginLeft: 0,
  },
  or: {},
  error: {
    color: theme.palette.error.dark,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const SignIn = ({ history, context }) => {
  const classes = useStyles();
  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={(values, actions) =>
        context.login(values).then(
          (userCredential) => {
            actions.setSubmitting(false);
          },
          (error) => {
            actions.setSubmitting(false);
            actions.setErrors(error);
            actions.setStatus({ msg: error.message || "Could not login." });
          }
        )
      }
    >
      {({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className={classes.fields}>
            <TextField
              className={classes.field}
              error={errors.email && touched.email}
              name="email"
              placeholder="email address"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
            />
            <TextField
              className={classes.field}
              error={errors.password && touched.password}
              type="password"
              name="password"
              placeholder="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
            />
          </div>
          <div className={classes.submit}>
            {status && status.msg && (
              <Typography
                className={classes.error}
                color="error"
                align="center"
                variant="subtitle1"
              >
                {status.msg}
              </Typography>
            )}
            <Button
              color="secondary"
              size="large"
              className={classes.button}
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Log In
            </Button>
            <Button
              size="large"
              className={classes.button}
              variant="contained"
              onClick={context.loginWithGoogle}
              disabled={isSubmitting}
            >
              Log In With Google
            </Button>
            <Typography className={classes.or} align="center" variant="body1">
              or
            </Typography>
            <Button
              size="large"
              className={classes.button}
              variant="contained"
              disabled={isSubmitting}
              onClick={() => history.push(routes.EMAIL_LINK)}
            >
              Get a magic link
            </Button>
            <MuiLink
              className={classes.link}
              to={routes.PASSWORD_RESET}
              component={Link}
            >
              <Typography display="inline" variant="subtitle1">
                Forgot your password?
              </Typography>
            </MuiLink>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignIn;
