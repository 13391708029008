import React, { useContext } from "react";
import { AppContext } from "../../../components/AppContext";
import { Typography, makeStyles } from "@material-ui/core";
import AddPlaysetForm from "./AddPlaysetForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    padding: theme.spacing(2),
    maxWidth: "500px",
    minWidth: "300px",
    width: "100%",
  },
  text: {
    color: theme.palette.primary.main,
  },
}));

const AddPlayset = () => {
  const context = useContext(AppContext);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography className={classes.text} align="center" variant="h6">
          New Playset
        </Typography>
        <AddPlaysetForm context={context} />
      </div>
    </div>
  );
};

export default AddPlayset;
