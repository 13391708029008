import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  typography: {
    fontFamily: ["Questrial", "Roboto"].join(","),
  },
  palette: {
    primary: {
      light: "#426da0",
      main: "#004271",
      dark: "#001c45",
      contrastText: "white",
    },
    secondary: {
      light: "#518dd6",
      main: "#0060a4",
      dark: "#003775",
      contrastText: "white",
    },
    error: {
      light: "#f27573",
      main: "#ef5350",
      dark: "#a73a38",
      contrastText: "black",
    },
  },
});

const AppThemeProvider = ({ children }) => (
  <div>
    <ThemeProvider theme={theme}>
      <CssBaseline>{children}</CssBaseline>
    </ThemeProvider>
  </div>
);

export default AppThemeProvider;
