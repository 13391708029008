import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { Typography, TextField, makeStyles, Button } from "@material-ui/core";
import * as routes from "../../routes";

const useStyles = makeStyles((theme) => ({
  fields: {
    display: "flex",
    flexDirection: "column",
  },
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  submit: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.contrastText,
    marginLeft: 0,
  },
  error: {
    color: theme.palette.error.dark,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const SignUp = ({ history, context }) => {
  const classes = useStyles();

  const handleRegister = (values, actions) =>
    context.register(values).then(
      (userCredential) => {
        actions.setSubmitting(false);
        history.push(routes.ONBOARDING);
      },
      (error) => {
        actions.setSubmitting(false);
        actions.setErrors(error);
        actions.setStatus({ msg: error.message });
      }
    );

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={handleRegister}
    >
      {({
        values,
        status,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className={classes.fields}>
            <TextField
              className={classes.field}
              type="email"
              name="email"
              placeholder="email address"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              autoComplete="on"
              variant="outlined"
            />
            <ErrorMessage name="email" component="div" />
            <TextField
              className={classes.field}
              type="password"
              name="password"
              placeholder="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              autoComplete="on"
              variant="outlined"
            />
            <ErrorMessage name="password" component="div" />
          </div>
          {status && status.msg && (
            <Typography
              className={classes.error}
              color="error"
              align="center"
              variant="subtitle1"
            >
              {status.msg}
            </Typography>
          )}
          <div className={classes.submit}>
            <Button
              size="large"
              color="secondary"
              className={classes.button}
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Sign Up
            </Button>
            <Button
              size="large"
              className={classes.button}
              variant="contained"
              onClick={context.loginWithGoogle}
              disabled={isSubmitting}
            >
              Sign Up With Google
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignUp;
