import React from "react";
import { Formik, Form } from "formik";
import { Typography, TextField, makeStyles, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  fields: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    marginTop: theme.spacing(1),
  },
  submit: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  button: {
    marginTop: theme.spacing(2),
  },
  error: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.error.dark,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const EditSettingsForm = ({ settings, context, onUpdate }) => {
  const classes = useStyles();

  const handleSave = (settings, actions) => {
    context.saveSettings(settings).then(
      (data) => {
        context.setMessage({ body: "Settings saved.", options: {} });
        actions && actions.setSubmitting(false);
      },
      (error) => {
        actions && actions.setSubmitting(false);
        actions && actions.setErrors(error);
        actions && actions.setStatus({ msg: error.message });
        context.setMessage({
          body: error.message || "Could not save settings.",
          options: {},
        });
      }
    );
  };
  return (
    <Formik initialValues={settings} onSubmit={handleSave}>
      {({
        values,
        status,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        errors,
        touched,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className={classes.fields}>
            <TextField
              className={classes.field}
              error={errors.description && touched.description}
              label="Waiver form URL"
              name="waiverFormUrl"
              placeholder="URL"
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.waiverFormUrl || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              className={classes.field}
              error={errors.description && touched.description}
              label="Welcome video Id"
              name="welcomeVideo"
              placeholder="YouTube video Id"
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.welcomeVideo || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <div className={classes.submit}>
            <Button
              color="secondary"
              fullWidth
              className={classes.button}
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Save
            </Button>
            {status && status.msg && (
              <Typography
                className={classes.error}
                color="error"
                align="center"
                variant="body1"
              >
                {status.msg}
              </Typography>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditSettingsForm;
