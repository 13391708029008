import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { AppContext } from "../../components/AppContext";
import PasswordResetForm from "./PasswordResetForm";
import { Typography, makeStyles, Toolbar, Button } from "@material-ui/core";
import * as routes from "../../routes";
import * as modes from "../../modes";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  content: {
    padding: theme.spacing(2),
    minWidth: "300px",
    maxWidth: "400px",
  },
  text: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttons: {
    marginLeft: "auto",
  },
}));

const PasswordReset = ({ history, location }) => {
  const ctx = useContext(AppContext);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Toolbar>
        <div className={classes.buttons}>
          <Button
            color="inherit"
            variant="outlined"
            onClick={() =>
              history.push({
                pathname: routes.ROOT,
                state: { mode: modes.SIGNIN },
              })
            }
          >
            Log In
          </Button>
        </div>
      </Toolbar>
      <div className={classes.container}>
        <div className={classes.content}>
          <Typography className={classes.text} align="center" variant="h5">
            Forgot your password?
          </Typography>
          <Typography
            className={classes.text}
            align="center"
            variant="subtitle1"
          >
            Enter your email address to reset your password. You may need to
            check your spam folder.
          </Typography>
          <PasswordResetForm
            history={history}
            location={location}
            context={ctx}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(PasswordReset);
