import React, { useContext } from "react";
import { AppContext } from "../../components/AppContext";
import ProfileForm from "./ProfileForm";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    padding: theme.spacing(2),
    maxWidth: "500px",
    minWidth: "300px",
    width: "100%",
  },
  text: {
    color: theme.palette.primary.main,
  },
}));

const Profile = () => {
  const context = useContext(AppContext);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography className={classes.text} align="center" variant="h6">
          Profile
        </Typography>
        <ProfileForm context={context} />
      </div>
    </div>
  );
};

export default Profile;
