import React from "react";
import UrlsList from "./UrlsList";
import UrlAddForm from "./UrlAddForm";
import { Typography, makeStyles, Button } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/WebAsset";

const groups = [
  { title: "General", sort: 1 },
  { title: "Guides", sort: 2 },
  { title: "Specifications", sort: 3 },
  { title: "Manuals", sort: 4 },
  { title: "Unboxing and Separation", sort: 5 },
];

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  root: {},
  icon: { marginRight: theme.spacing(1) },
}));

const UrlsSection = ({ playset, handleRemove, handleSave }) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (x) => {
    setOpen(false);
    handleSave({ ...playset, urls: { ...(playset.urls || {}), [x.url]: x } });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const classes = useStyles();
  return (
    <div
      style={{
        borderRadius: 8,
        border: "1px solid #cdcdcd",
        padding: 4,
      }}
    >
      <Typography variant="overline" style={{ marginLeft: 8 }}>
        Links
      </Typography>
      <UrlsList
        urls={playset.urls ? Object.values(playset.urls) : []}
        handleRemove={handleRemove}
        busy={false}
      />
      <UrlAddForm
        groups={groups}
        open={open}
        onClose={handleClose}
        onCancel={handleCancel}
      />
      <Button color="primary" aria-label="upload picture" onClick={handleOpen}>
        <LinkIcon className={classes.icon} />
        Add Link
      </Button>
    </div>
  );
};

export default UrlsSection;
