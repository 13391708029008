import React from "react";
import UrlsList from "./UrlsList";
import SectionHeader from "./SectionHeader";

const UrlsSection = ({ playset, handleRemove, handleSave }) => {
  return (
    playset.urls.length > 0 && (
      <div>
        <SectionHeader text="Links" />
        <UrlsList
          urls={playset.urls ? Object.values(playset.urls) : []}
          busy={false}
        />
      </div>
    )
  );
};

export default UrlsSection;
