import { useState, useEffect } from "react";
import { files } from "../../firebase";

export default function FileUploader(path) {
  const [busy, setBusy] = useState(false);
  const [item, setItem] = useState();

  const getFileMeta = async (ref) => {
    const url = await ref.getDownloadURL();
    const metadata = await ref.getMetadata();
    return {
      url,
      path: ref.fullPath,
      name: ref.name,
      bucket: ref.bucket,
      metadata,
    };
  };

  const getFile = async (path) => {
    const ref = files.ref(path);
    ref
      .getDownloadURL()
      .then(() => getFileMeta(ref))
      .then((f) => {
        setItem(f);
      })
      .catch((e) => console.log("This is ok", e));
  };

  const load = (file, customMetadata) => {
    setBusy(true);
    const task = files.ref(path).put(file);
    task.on(
      "state_changed",
      (snapshot) => {
        console.log("progress");
      },
      (error) => {
        console.log(error);
        setBusy(false);
      },
      async () => {
        const fileRef = files.ref(path);
        await fileRef.updateMetadata({
          customMetadata,
        });
        await getFile(path);
        setBusy(false);
      }
    );
  };

  const remove = (file) => {
    files
      .ref(file.path)
      .delete()
      .then(async () => {
        setBusy(true);
        await getFile(path);
        setBusy(false);
      });
  };

  useEffect(() => {
    if (!path) {
      return;
    }
    setBusy(true);
    getFile(path);
    setBusy(false);
  }, [path]);

  return [load, remove, busy, item];
}
