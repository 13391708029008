import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AppContext } from "../AppContext";
import * as routes from "../../routes";
import * as modes from "../../modes";

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
  const { loggedIn } = useContext(AppContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        loggedIn && auth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: routes.ROOT,
              state: { from: props.location, mode: modes.SIGNIN },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
