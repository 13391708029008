import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AppContext } from "../../components/AppContext";
import { Typography, makeStyles, LinearProgress } from "@material-ui/core";
import ChangePasswordForm from "./ChangePasswordForm";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    padding: theme.spacing(2),
    minWidth: "300px",
    maxWidth: "500px",
    width: "100%",
  },
  text: {
    color: theme.palette.primary.main,
  },
}));

const Account = ({ history }) => {
  const [busy, setBusy] = useState(false);
  const ctx = useContext(AppContext);
  const classes = useStyles();
  const [hasEmailPassword, setHasEmailPassword] = useState(false);

  useEffect(() => {
    setBusy(true);
    ctx.hasEmailPassword().then((has) => {
      setHasEmailPassword(has);
      setBusy(false);
    });
  }, [ctx]);

  return (
    <React.Fragment>
      {busy && <LinearProgress color="secondary" variant="query" />}
      <div className={classes.root}>
        {!busy && (
          <div className={classes.container}>
            <Typography className={classes.text} align="center" variant="h6">
              Security
            </Typography>
            {hasEmailPassword ? (
              <React.Fragment>
                <ChangePasswordForm history={history} context={ctx} />
              </React.Fragment>
            ) : (
              <Typography
                className={classes.text}
                align="center"
                variant="body1"
              >
                You don't have a password because you logged in with a third
                party account like Google.
              </Typography>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Account);
