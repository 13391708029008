import React, { useState, useEffect } from "react";
import { firebase } from "../../../../firebase";
import { Button, makeStyles } from "@material-ui/core";
import MetadataDialog from "./MetadataDialog";

const useStyles = makeStyles((theme) => ({
  root: {},
  input: {
    display: "none",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const FileInput = ({
  onUpload,
  accept,
  title,
  Icon,
  id,
  groups,
  skipMetadata,
}) => {
  const [files, setFiles] = useState([]);
  const [prompt, setPrompt] = useState(false);
  useEffect(() => {
    if (files[0]) {
      setPrompt(!skipMetadata);
    }
  }, [files]);

  const classes = useStyles();
  return (
    <div>
      <MetadataDialog
        name={files[0] ? files[0].name : ""}
        open={prompt}
        onClose={(title, group) => {
          onUpload(files[0], {
            title: title || files[0].name,
            group: group || "General",
            timestamp: Date.now().toString(),
            user: firebase.auth().currentUser.uid,
          });
          setPrompt(false);
        }}
        onCancel={() => {
          setPrompt(false);
        }}
        groups={groups}
      />
      <input
        accept={accept}
        className={classes.input}
        id={id}
        type="file"
        value=""
        onChange={(e) => {
          if (e.target.files[0]) {
            setFiles([...e.target.files]);
            if (skipMetadata) {
              onUpload(e.target.files[0], {
                timestamp: Date.now().toString(),
                user: firebase.auth().currentUser.uid,
              });
            }
          }
        }}
      />
      <label htmlFor={id}>
        <Button color="primary" aria-label="upload picture" component="span">
          <Icon className={classes.icon} />
          {title}
        </Button>
      </label>
    </div>
  );
};

export default FileInput;
