import React from "react";
import AppThemeProvider from "./AppThemeProvider";
import { AppContextConsumer } from "../components/AppContext";
import { AppContextProvider } from "../components/AppContext";
import AppRouter from "./AppRouter";
import ErrorBoundary from "../components/ErrorBoundary";

const App = () => (
  <AppContextProvider>
    <AppThemeProvider>
      <ErrorBoundary>
        <AppContextConsumer>{() => <AppRouter />}</AppContextConsumer>
      </ErrorBoundary>
    </AppThemeProvider>
  </AppContextProvider>
);

export default App;
