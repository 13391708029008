import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ImagesSection from "./ImagesSection";
import DocumentsSection from "./DocumentsSection";
import UrlsSection from "./UrlsSection";
import Playlist from "./Playlist";

const useStyles = makeStyles((theme) => ({}));

const Playset = ({ playset }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <Playlist playlistId={playset.playlistId} />
        <DocumentsSection playset={playset} />
        <UrlsSection playset={playset} />
        <ImagesSection playset={playset} />
      </div>
    </div>
  );
};

export default Playset;
