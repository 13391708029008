import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Drawer,
  Hidden,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Typography,
  Link,
} from "@material-ui/core";
import UserIcon from "@material-ui/icons/Group";
import HomeIcon from "@material-ui/icons/Home";
import PlaysetIcon from "@material-ui/icons/SportsKabaddi";
import SettingsIcon from "@material-ui/icons/Settings";
import * as routes from "../../../routes";
import { isAdmin } from "../../../utilities";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: ({ drawerWidth }) => ({
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  }),
  link: {
    color: "white",
    cursor: "pointer",
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: theme.palette.primary.light,
    ...theme.mixins.toolbar,
  },
  branding: {
    color: "white",
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
    color: "white",
  },
  drawerPaper: {
    width: ({ drawerWidth }) => drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const AppDrawer = ({
  history,
  setMobileOpen,
  mobileOpen,
  drawerWidth,
  claims,
}) => {
  const classes = useStyles({ drawerWidth });
  const theme = useTheme();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigateSearchUsers = (mobile) => () => {
    mobile && setMobileOpen(!mobileOpen);
    history.push({ pathname: routes.USERS_SEARCH });
  };

  const handleNavigateHome = (mobile) => () => {
    mobile && setMobileOpen(!mobileOpen);
    history.push({ pathname: routes.HOME });
  };

  const handleNavigateSettings = (mobile) => () => {
    mobile && setMobileOpen(!mobileOpen);
    history.push({ pathname: routes.SETTINGS });
  };

  const handleNavigatePlaysets = (mobile) => () => {
    mobile && setMobileOpen(!mobileOpen);
    history.push({ pathname: routes.PLAYSETS });
  };

  const DrawerContents = ({ classes, mobile, claims }) => (
    <div>
      <div className={classes.toolbar}>
        <Typography className={classes.branding} variant="subtitle1">
          <Link
            underline="none"
            className={classes.link}
            onClick={handleNavigateHome(mobile)}
          >
            Roc Solid On Demand
          </Link>
        </Typography>
      </div>

      <Divider />
      <List>
        <ListItem
          button
          divider
          key="Home"
          onClick={handleNavigateHome(mobile)}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        {isAdmin(claims) && (
          <List
            subheader={
              <ListSubheader color="primary">Administration</ListSubheader>
            }
          >
            {claims.canReadUser && (
              <ListItem
                button
                divider
                key="Users"
                onClick={handleNavigateSearchUsers(mobile)}
              >
                <ListItemIcon>
                  <UserIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItem>
            )}
            {claims.canReadPlayset && (
              <ListItem
                button
                divider
                key="Playsets"
                onClick={handleNavigatePlaysets(mobile)}
              >
                <ListItemIcon>
                  <PlaysetIcon />
                </ListItemIcon>
                <ListItemText primary="Playsets" />
              </ListItem>
            )}
            {claims.canReadSettings && (
              <ListItem
                button
                divider
                key="Settings"
                onClick={handleNavigateSettings(mobile)}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItem>
            )}
          </List>
        )}
      </List>
    </div>
  );

  return (
    <React.Fragment>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <DrawerContents
            classes={classes}
            mobile={true}
            claims={claims}
            history={history}
          />
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <DrawerContents
            classes={classes}
            mobile={false}
            claims={claims}
            history={history}
          />
        </Drawer>
      </Hidden>
    </React.Fragment>
  );
};

export default withRouter(AppDrawer);
