import React from "react";
import Snackbar from "@material-ui/core/Snackbar";

const AppSnackbar = ({ message, open, handleClose }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={open}
      onClose={handleClose}
      autoHideDuration={4000}
      ContentProps={{
        "aria-describedby": "message-id"
      }}
      message={message ? <span id="message-id">{message.body}</span> : ""}
    />
  );
};

export default AppSnackbar;
