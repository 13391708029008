import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { AppContext } from "../../components/AppContext";
import { Typography, makeStyles } from "@material-ui/core";
import * as routes from "../../routes";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  container: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    minWidth: "300px",
    maxWidth: "300px"
  },
  text: {
    color: theme.palette.primary.contrastText
  }
}));

const VerifyEmailLink = () => {
  const ctx = useContext(AppContext);
  const classes = useStyles();
  const [verified, setVerified] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    ctx
      .confirmEmailLink(window.location.href)
      .then(response => setVerified(true))
      .catch(error => setMessage(error.message));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {verified ? (
          <Redirect
            to={{
              pathname: routes.HOME
            }}
          />
        ) : message ? (
          <Typography className={classes.text} align="center" variant="h6">
            {message}
          </Typography>
        ) : (
          <Typography className={classes.text} align="center" variant="h6">
            Please wait while we verify your link
          </Typography>
        )}
      </div>
    </div>
  );
};

export default VerifyEmailLink;
