import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { AppContext } from "../../components/AppContext";
import { Typography, Link } from "@material-ui/core";
import SignUp from "./SignUp";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
}));

const SignUpWrapper = ({ history, switchMode }) => {
  const classes = useStyles();
  const context = useContext(AppContext);

  return (
    <div className={classes.root}>
      <Typography variant="h5">Sign up</Typography>
      <Typography display="inline" variant="subtitle1">
        or <Link onClick={switchMode}>log in to your account</Link>
      </Typography>

      <SignUp history={history} context={context} />
    </div>
  );
};

export default withRouter(SignUpWrapper);
