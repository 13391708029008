import React from "react";
import { Formik, Form, Field } from "formik";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  makeStyles,
  Divider,
  InputBase,
  Button,
} from "@material-ui/core";
import { Switch } from "formik-material-ui";
import { isAdmin } from "../../../utilities";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
}));

const EditUserForm = ({ user, context }) => {
  const classes = useStyles();

  const userIsSelf = (context, user) => context.currentUser.uid === user.uid;
  const canEditThisUser = (context, user) =>
    context.claims.canUpdateUser && !userIsSelf(context, user);

  const handleSave = (user, actions) => {
    const userRecord = {
      email: user.email || null,
      emailVerified: user.emailVerified,
      phoneNumber: user.phoneNumber || null,
      password: user.password || null,
      displayName: user.displayName || null,
      photoURL: user.photoURL || null,
      disabled: user.disabled,
      admin: user.admin,
    };
    context
      .updateUser({ userRecord, token: context.token, uid: user.uid })
      .then(
        (user) => {
          context.setMessage({ body: "User saved.", options: {} });
          actions.setSubmitting(false);
        },
        (error) => {
          actions.setSubmitting(false);
          actions.setErrors(error);
          actions.setStatus({ msg: error.message });
          context.setMessage({
            body: error.message || "Could find update user.",
            options: {},
          });
        }
      );
  };

  return (
    <Formik
      initialValues={{ ...user, admin: isAdmin(user.customClaims) }}
      onSubmit={handleSave}
    >
      {({
        values,
        status,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          {values.photoURL && (
            <Avatar
              alt={values.displayName}
              src={values.photoURL}
              className={classes.avatar}
            />
          )}
          <List>
            {!canEditThisUser(context, user) ? (
              <React.Fragment>
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{ color: "error" }}
                    primary="Permission denied"
                    secondary={
                      userIsSelf(context, user)
                        ? "You are not allowed to edit your own user record."
                        : "You can't edit this user."
                    }
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            ) : null}
            <ListItem>
              <ListItemText primary="Id" secondary={values.uid} />
            </ListItem>
            <Divider />

            <ListItem>
              <ListItemText
                primary="Display Name"
                secondary={
                  <InputBase
                    disabled={!canEditThisUser(context, user)}
                    style={{ width: "100%" }}
                    name="displayName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.displayName}
                    autoComplete="on"
                  />
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary="Email"
                secondary={
                  <InputBase
                    style={{ width: "100%" }}
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Email Verified" />
              <ListItemSecondaryAction>
                <Field
                  name="emailVerified"
                  component={Switch}
                  type="checkbox"
                  disabled={!canEditThisUser(context, user)}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Account Disabled" />
              <ListItemSecondaryAction>
                <Field
                  name="disabled"
                  component={Switch}
                  type="checkbox"
                  disabled={!canEditThisUser(context, user)}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Administrator" />
              <ListItemSecondaryAction>
                <Field
                  name="admin"
                  component={Switch}
                  type="checkbox"
                  disabled={!canEditThisUser(context, user)}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary="Last SignIn"
                secondary={values.metadata.lastSignInTime}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary="Created"
                secondary={values.metadata.creationTime}
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary="Tokens Valid After"
                secondary={values.tokensValidAfterTime}
              />
            </ListItem>
            <Divider />
          </List>
          <Button
            color="secondary"
            size="large"
            variant="contained"
            type="submit"
            disabled={isSubmitting || !canEditThisUser(context, user)}
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default EditUserForm;
