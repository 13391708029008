import React from "react";
import {
  makeStyles,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Divider,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  root: {},
  icon: {
    color: "white",
  },
}));

const compare = (a, b) => {
  return a.name.localeCompare(b.name);
};

const groupBy = (array) => {
  const groups = array.reduce((result, currentValue) => {
    (result[currentValue.metadata.customMetadata.group] =
      result[currentValue.metadata.customMetadata.group] || []).push(
      currentValue
    );
    return result;
  }, {});
  const groupNames = Object.keys(groups).sort();
  return groupNames.map((name) => ({ name, docs: groups[name] }));
};

const DocumentList = ({ documents, busy }) => {
  const classes = useStyles();
  const groups = groupBy(documents);
  return (
    <div className={classes.wrapper}>
      {busy && <LinearProgress color="secondary" />}
      <div className={classes.root}>
        {groups.map((group) => (
          <List
            key={group.name}
            subheader={
              <ListSubheader color="primary">{group.name}</ListSubheader>
            }
          >
            {group.docs.sort(compare).map((doc) => (
              <React.Fragment key={doc.path}>
                <ListItem
                  dense
                  button
                  component="a"
                  href={doc.url}
                  target="_blank"
                >
                  <ListItemText
                    primary={doc.metadata.customMetadata.title || doc.name}
                    secondary={doc.name}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        ))}
      </div>
    </div>
  );
};

export default DocumentList;
