import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import { TextField, Button, Typography, makeStyles } from "@material-ui/core";
import { AppContext } from "../../../components/AppContext";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import * as routes from "../../../routes";

const useStyles = makeStyles((theme) => ({
  fields: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    marginTop: theme.spacing(1),
  },
  submit: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  button: {
    marginTop: theme.spacing(2),
  },
  error: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.error.dark,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const AddPlaysetForm = ({ history }) => {
  const context = useContext(AppContext);
  const classes = useStyles();

  const validate = (values, props) => {
    const errors = {};

    return errors;
  };

  return (
    <Formik
      initialValues={{ enabled: false, description: "" }}
      validate={validate}
      onSubmit={(values, actions) => {
        context.createPlayset(values).then(
          (result) => {
            context.setMessage({ body: "Playset added.", options: {} });
            actions.setSubmitting(false);
            history.push(routes.PLAYSETS);
          },
          (error) => {
            actions.setSubmitting(false);
            actions.setErrors(error);
            actions.setStatus({ msg: "Could not add playset." });
          }
        );
      }}
    >
      {({
        values,
        errors,
        status,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className={classes.fields}>
            <TextField
              className={classes.field}
              error={errors.description && touched.description}
              label="Description"
              name="description"
              placeholder="description"
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.description || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.field}
                  name="enabled"
                  checked={values.enabled}
                  color="primary"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
              label="Enabled"
            />
          </div>
          <div className={classes.submit}>
            <Button
              color="secondary"
              fullWidth
              className={classes.button}
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Save
            </Button>
            {status && status.msg && (
              <Typography
                className={classes.error}
                color="error"
                align="center"
                variant="body1"
              >
                {status.msg}
              </Typography>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(AddPlaysetForm);
