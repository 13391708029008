import React from "react";
import ImageList from "./ImageList";
import { Typography } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import FileInput from "./FileInput";
import FilesUploader from "../../../components/FilesUploader";

const groups = [{ title: "General", sort: 1 }];

const ImagesSection = ({ playset }) => {
  const [uploadImage, removeImage, loadingImages, images] = FilesUploader(
    playset,
    "images/playsets"
  );

  const handleRemove = (file) => (e) => {
    e.preventDefault();
    removeImage(file);
  };
  return (
    <div
      style={{
        borderRadius: 8,
        border: "1px solid #cdcdcd",
        padding: 4,
        marginBottom: 16,
      }}
    >
      <Typography variant="overline" style={{ marginLeft: 8 }}>
        Images
      </Typography>
      <ImageList
        images={images}
        handleRemove={handleRemove}
        busy={loadingImages}
      />
      <FileInput
        id="upload-image"
        accept="image/*"
        onUpload={uploadImage}
        title="Add Image"
        Icon={ImageIcon}
        groups={groups}
      />
    </div>
  );
};

export default ImagesSection;
