import React from "react";
import {
  IconButton,
  makeStyles,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
  Divider,
} from "@material-ui/core";
import TrashIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  root: {},
  icon: {
    color: "white",
  },
}));

const compare = (a, b) => {
  return a.title.localeCompare(b.title);
};

const groupBy = (array) => {
  const groups = array.reduce((result, currentValue) => {
    (result[currentValue.group] = result[currentValue.group] || []).push(
      currentValue
    );
    return result;
  }, {});
  const groupNames = Object.keys(groups).sort();
  return groupNames.map((name) => ({ name, objects: groups[name] }));
};

const UrlsList = ({ urls, handleRemove, busy }) => {
  const classes = useStyles();
  const groups = groupBy(urls);
  return (
    <div className={classes.wrapper}>
      {busy && <LinearProgress color="secondary" />}
      <div className={classes.root}>
        {groups.map((group) => (
          <List
            key={group.name}
            subheader={
              <ListSubheader color="primary">{group.name}</ListSubheader>
            }
          >
            {group.objects.sort(compare).map((obj) => (
              <React.Fragment key={obj.url}>
                <ListItem button component="a" href={obj.url} target="_blank">
                  <ListItemText primary={obj.title} secondary={obj.url} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemove(obj)}
                    >
                      <TrashIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        ))}
      </div>
    </div>
  );
};

export default UrlsList;
