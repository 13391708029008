import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Toolbar, Button } from "@material-ui/core";
import SignUpWrapper from "../SignUpWrapper";
import SignInWrapper from "../SignInWrapper";
import * as modes from "../../../modes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  welcome: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(4),
  },
  welcomeText: {
    color: theme.palette.primary.contrastText,
    margin: theme.spacing(4),
  },
  action: {
    minWidth: "400px",
  },
  actionContent: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  buttons: {
    marginLeft: "auto",
  },
  brand: {
    flexGrow: 1,
    color: "white",
  },
  signin: {
    color: "white",
  },
}));

const DesktopLanding = ({
  mode,
  handleSwitchToSignIn,
  handleSwitchToSignUp,
  tagline,
  description,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.welcome}>
        <Toolbar>
          <Typography variant="h6" align="left" className={classes.brand}>
            Roc Solid On Demand
          </Typography>
        </Toolbar>
        <Typography variant="h3" align="left" className={classes.welcomeText}>
          {tagline}
        </Typography>
        <Typography
          variant="subtitle1"
          align="left"
          className={classes.welcomeText}
        >
          {description}
        </Typography>
      </div>
      <div className={classes.action}>
        <Toolbar>
          <div className={classes.buttons}>
            {mode === modes.SIGNIN ? (
              <Button
                color="inherit"
                variant="outlined"
                onClick={handleSwitchToSignUp}
              >
                Sign Up
              </Button>
            ) : (
              <Button
                color="inherit"
                variant="outlined"
                onClick={handleSwitchToSignIn}
              >
                Log In
              </Button>
            )}
          </div>
        </Toolbar>
        <div className={classes.actionContent}>
          {mode === modes.SIGNUP || mode === modes.WELCOME ? (
            <SignUpWrapper switchMode={handleSwitchToSignIn} />
          ) : (
            <SignInWrapper switchMode={handleSwitchToSignUp} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DesktopLanding;
