import React, { useContext, useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import YouTube from "react-youtube";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
  Divider,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import { AppContext } from "../../../components/AppContext";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  root: {},
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 16,
    marginTop: 16,
  },
  video: {
    padding: 0,
  },
  list: {
    width: "100%",
  },
}));

const Playlist = ({ playlistId }) => {
  const [loadingPlaylist, setLoadingPlaylist] = useState(true);
  const [playlist, setPlaylist] = useState();
  const [video, setVideo] = useState();
  const context = useContext(AppContext);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const smallOpts = {
    id: "rocsolidondemandvideowindow",
    width: 325,
    height: 183,
    playerVars: {
      autoplay: 0,
    },
  };
  const largeOpts = {
    id: "rocsolidondemandvideowindow",
    width: 640,
    height: 360,
    playerVars: {
      autoplay: 0,
    },
  };
  const onReady = (event) => {
    // access to player in all event handlers via event.target
    //event.target.pauseVideo();
  };
  useEffect(() => {
    if (!playlistId) {
      return;
    }
    context.getPlaylist({ playlistId }).then((data) => {
      setPlaylist(data);
      setVideo(data.items[0].snippet.resourceId.videoId);
      setLoadingPlaylist(false);
    });
  }, [playlistId]);

  const handleSelection = (id) => (e) => {
    setVideo(id);
  };

  return (
    <div className={classes.root}>
      {playlist && (
        <div>
          <SectionHeader text="Videos" />
          <div className={classes.wrapper}>
            {video && (
              <div className={classes.video}>
                <YouTube
                  videoId={video}
                  opts={isSmall ? smallOpts : largeOpts}
                  onReady={onReady}
                />
              </div>
            )}
            <List className={classes.list}>
              {playlist.items.map((x) => (
                <React.Fragment key={x.id}>
                  <ListItem
                    button
                    dense
                    key={x.id}
                    onClick={handleSelection(x.snippet.resourceId.videoId)}
                  >
                    <ListItemAvatar>
                      <Avatar
                        variant="square"
                        alt={x.snippet.title}
                        src={x.snippet.thumbnails.default.url}
                      />
                    </ListItemAvatar>

                    <ListItemText primary={x.snippet.title} />
                    {x.snippet.resourceId.videoId === video && (
                      <ListItemSecondaryAction>
                        <IconButton
                          color="primary"
                          edge="end"
                          aria-label="play"
                          onClick={handleSelection(
                            x.snippet.resourceId.videoId
                          )}
                        >
                          <CheckIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </div>
        </div>
      )}
    </div>
  );
};

export default Playlist;
