import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

export default function MetadataDialog({
  name,
  open,
  onClose,
  onCancel,
  groups = [],
}) {
  const [title, setTitle] = useState(name);
  const [group, setGroup] = useState("General");
  return (
    <div>
      <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Metadata</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Give this file a name and group.
          </DialogContentText>
          <FormControl>
            <InputLabel htmlFor="file-group">Group</InputLabel>
            <Select
              native
              value={group}
              onChange={(e) => setGroup(e.target.value)}
              inputProps={{
                name: "group",
                id: "file-group",
              }}
            >
              {groups.map((x) => (
                <option key={x.title} value={x.title}>
                  {x.title}
                </option>
              ))}
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Title"
            type="text"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onCancel()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onClose(title, group)} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
