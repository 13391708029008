import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import { Typography, makeStyles, LinearProgress } from "@material-ui/core";
import { AppContext } from "../../../components/AppContext";
import UserSearchForm from "./UserSearchForm";
import * as routes from "../../../routes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    padding: theme.spacing(2),
    minWidth: 300,
    width: "100%",
  },
  text: {
    color: theme.palette.primary.main,
  },
}));

const SearchUser = ({ history }) => {
  const context = useContext(AppContext);
  const classes = useStyles();
  const [busy, setBusy] = useState(false);

  return (
    <React.Fragment>
      {busy && <LinearProgress color="secondary" variant="query" />}
      <div className={classes.root}>
        <div className={classes.container}>
          <Typography className={classes.text} align="center" variant="h6">
            Find User
          </Typography>
          <UserSearchForm
            context={context}
            onGotUser={(user) =>
              user &&
              history.push({
                pathname: `${routes.USERS}/${user.uid}`,
                state: { user: user },
              })
            }
            setBusy={setBusy}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SearchUser);
