import React, { useContext, useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter, Redirect } from "react-router-dom";
import { AppContext } from "../../components/AppContext";
import * as routes from "../../routes";
import MobileLanding from "./MobileLanding";
import DesktopLanding from "./DesktopLanding";
import * as modes from "../../modes";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const tagline = "Communities building hope for kids fighting cancer";
const description =
  "Roc Solid Foundation® builds hope for kids fighting cancer by offering opportunities for them to do what they do best – PLAY.";

const Landing = ({ history, location }) => {
  const classes = useStyles();
  const { loggedIn } = useContext(AppContext);
  const [mode, setMode] = useState(modes.WELCOME);
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const state = location.state || { mode: modes.WELCOME };
    state.mode !== undefined && setMode(state.mode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const handleSwitchToSignIn = () => {
    history.push({
      pathname: routes.ROOT,
      state: { mode: modes.SIGNIN },
    });
  };

  const handleSwitchToSignUp = () => {
    history.push({
      pathname: routes.ROOT,
      state: { mode: modes.SIGNUP },
    });
  };

  const handleSwitchToWelcome = () => {
    history.push({
      pathname: routes.ROOT,
      state: { mode: modes.WELCOME },
    });
  };

  return loggedIn ? (
    <Redirect
      to={{
        pathname: routes.HOME,
      }}
    />
  ) : (
    <div className={classes.root}>
      {mobileSize ? (
        <MobileLanding
          mode={mode}
          setMode={setMode}
          handleSwitchToSignIn={handleSwitchToSignIn}
          handleSwitchToSignUp={handleSwitchToSignUp}
          handleSwitchToWelcome={handleSwitchToWelcome}
          tagline={tagline}
          description={description}
        />
      ) : (
        <DesktopLanding
          mode={mode}
          handleSwitchToSignIn={handleSwitchToSignIn}
          handleSwitchToSignUp={handleSwitchToSignUp}
          tagline={tagline}
          description={description}
        />
      )}
    </div>
  );
};

export default withRouter(Landing);
